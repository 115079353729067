<template>
    <div class="modal fade" id="kt_modal_select_product" ref="selectProductModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_select_product_header">
                    <h2 class="fw-bolder">{{ $t('pages.module.lecture.selectProduct.title') }}</h2>
                    <div id="kt_modal_select_product_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSelectProduct()" :model="selectProductForm.data" ref="selectProductForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_select_product_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.selectProduct.cols.product') }}</label>
                                <el-form-item prop="product_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="selectProductForm.data.product_id" class="w-100" :placeholder="$t('pages.module.lecture.selectProduct.chooseProduct')" filterable>
                                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="selectProductForm.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit">
                            <span v-if="!selectProductForm.loading" class="indicator-label">{{ $t("btn.select") }}</span>
                            <span v-if="selectProductForm.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "selectProduct",
    data() {
        return {
            selectProductForm: {
                loading: false,
                data: {}
            },
            products: []
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.lecture.selectProduct.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.lecture.title")]);
        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: { pageSize: 9999 }
        }).then((products) => {
            let tempProducts = [];

            products.forEach((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');
                if (product.can_use_module) {
                    tempProducts.push(product);
                }
            });

            this.products = tempProducts;

            if (this.products.length) {
                this.showModal(this.$refs.selectProductModal);
            } else {
                this.$swal.fire({
                    text: this.$t("pages.module.lecture.notFoundProduct"),
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: this.$t("btn.ok"),
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(() => {
                    this.$router.push("/module");
                });
            }
        });
    },
    methods: {
        onSelectProduct() {
            this.$refs.selectProductForm.validate((valid) => {
                if(valid) {
                    let hiddenFunction = () => {
                        this.$refs.selectProductModal.removeEventListener('hidden.bs.modal', hiddenFunction);
                        this.$router.push('/module/lecture?productID=' + this.selectProductForm.data.product_id);
                    };

                    this.$refs.selectProductModal.addEventListener('hidden.bs.modal', hiddenFunction);

                    this.hideModal(this.$refs.selectProductModal);
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style>
</style>